import { Controller } from "@hotwired/stimulus"

import { Current } from "current.js"

// Connects to data-controller="message"
export default class extends Controller {
  static targets = ["message", "creator"]
  static values = { userId: Number }

  connect() {
    if (this.userIdValue == Current.user.id) {
      this.messageTarget.classList.add("bg-red-50")
      this.creatorTarget.classList.add("ml-auto")
      this.creatorTarget.classList.add("text-right")
      this.messageTarget.classList.add("ml-auto")
    }
  }
}
