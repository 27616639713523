import { Controller } from "@hotwired/stimulus"
import { cable } from "@hotwired/turbo-rails"
import { Current } from "current.js"

const REFRESH_INTERVAL = 50 * 1000 // 50 seconds
const VISIBILITY_DELAY = 5000 // 5 seconds

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// Connects to data-controller="presence"
export default class extends Controller {
  async connect() {
    this.channel = await cable.subscribeTo({ channel: "ConversationPresenceChannel", conversation_id: Current.conversation.id }, {
      connected: this.websocketConnected,
      disconnected: this.websocketDisconnected,
    });

    this.wasVisible = true

    this.dispatch("mark_present", { detail: { conversationId: Current.conversation.id } })
  }

  disconnect() {
    this.stopRefreshTimer()
    this.channel?.unsubscribe()
  }

  websocketConnected = () => {
    this.connected = true
    this.startRefreshTimer()
  }

  websocketDisconnected = () => {
    this.connected = false
    this.stopRefreshTimer()
  }

  visibilityChanged() {
    if (this.isVisible) {
      this.visible()
    } else {
      this.hidden()
    }
  }

  visible = async () => {
    await delay(VISIBILITY_DELAY)

    if (this.connected && this.isVisible && !this.wasVisible) {
      this.channel.send({ action: "mark_present" })
      this.startRefreshTimer()
      this.wasVisible = true
    }
  }

  hidden = async () => {
    await delay(VISIBILITY_DELAY)

    if (this.connected && !this.isVisible && this.wasVisible) {
      this.channel.send({ action: "mark_absent" })
      this.stopRefreshTimer()
      this.wasVisible = false
    }
  }

  startRefreshTimer = () => {
    this.refreshTimer ??= setInterval(this.refresh, REFRESH_INTERVAL)
  }

  stopRefreshTimer = () => {
    clearInterval(this.refreshTimer)
    this.refreshTimer = null
  }

  refresh = () =>{
    this.channel.send({ action: "refresh" })
  }

  get isVisible() {
    return document.visibilityState === "visible"
  }
}
