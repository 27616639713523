import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="composer"
export default class extends Controller {
  static targets = ["submitBtn", "editor"]

  checkEmpty(event) {
    if (event.detail.html === "") {
      this.submitBtnTarget.disabled = true
    } else {
      this.submitBtnTarget.disabled = false
    }
  }

  disableButton() {
    this.submitBtnTarget.disabled = true
  }

  submit(event) {
    event.target.disabled = true

    if (!this.submitBtnTarget.disabled) {
      this.element.requestSubmit();

      setTimeout(() => {
        this.editorTarget.querySelector("richer-text-editor").clear();
      }, 20)
    }
  }
}
