// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "current.js"
import "./controllers"
import "./src/dropdown"
import "@afomera/richer-text"

const hljs = require('highlight.js')
hljs.configure({ languages: ['ruby', 'erb', 'bash', 'javascript'] })

document.addEventListener('turbo:load', (_event) => {
  document.querySelectorAll('.richer-text pre').forEach((block) => {
    hljs.highlightElement(block)
  })
});
